import { io } from 'socket.io-client';
import { EventEmitter } from 'events';
import jsonrpc from '../api/jsonrpc';
import RpcApiError from '../api/rpcApiError';

export default class IoClient {
  constructor(url) {
    this.messageEmitter = new EventEmitter();
    this.socket = io(url, {
      path: '/ws',
      autoConnect: false,
      reconnectionDelayMax: 3000,
      transports: ['websocket'],
    });
    this.socket.on('disconnect', console.log.bind(console, 'socket.disconnect'));
    this.socket.on('connect', console.log.bind(console, 'socket.connect'));
    this.socket.on('reconnect', console.log.bind(console, 'socket.reconnect'));
    this.socket.on('error', console.log.bind(console, 'socket.error'));
    this.socket.on('message', (message, data) => {
      // console.log('socket.message', message, data);
      this.messageEmitter.emit(message, data);
    });
  }

  get connected() {
    return this.socket.connected;
  }

  connect(query) {
    if (this.connected) return;
    setTimeout(() => {
      this.socket.io.opts.query = Object.assign({}, query);
      this.socket.connect();
    }, 3000);
    console.log('io-client.connect', { socket: this.socket, query });
  }

  disconnect() {
    if (this.connected) {
      this.socket.disconnect();
    }
    this.socket.io.opts.query = {};
  }

  off(message, callback) {
    this.messageEmitter.off(message, callback);
  }

  on(message, callback) {
    this.messageEmitter.on(message, callback);
  }

  sendMessage(message, data) {
    this.socket.send(message, data);
  }

  /**
   *
   * @param method
   * @param params
   * @return {Promise<any>}
   */
  async call(method, params) {
    return new Promise((resolve, reject) =>
      this.socket.emit('call-api', jsonrpc(method, params), (result) => {
        if (result && result.error && result.error.code < 0) {
          return reject(new RpcApiError(result.error));
        }
        return resolve((result && result.result) || {});
      })
    );
  }
}
