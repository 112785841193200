export const ACCESS_VIEW = 50;
export const ACCESS_EDIT = 70;

// DATABASE
export const PERM_CONTACTS = 'contacts';
export const PERM_HOURLY = 'hourly_rate';
export const PERM_DB_BASE = 'databaseBase';
export const PERM_DB_TEMPLATES = 'databaseTemplates';
export const PERM_DB_CATALOG = 'databaseCatalog';
export const PERM_DB_MATERIALS = 'databaseMaterials';
export const PERM_DB_LABOR = 'databaseLabor';

// Assignments
export const PERM_ASSIGNMENTS = 'assignments';

// Project Management
export const PERM_STRUCTURE = 'structure';
export const PERM_FINANCIALS = 'financials';
export const PERM_SUBMITTALL = 'submittal';
export const PERM_CHANGE_ORDERS = 'changeOrders';
export const PERM_RFI = 'rfi';
export const PERM_SCHEDULE_PERMITS_INSPECTIONS = 'schedulePermitsInspections';
export const PERM_DOCUMENTS = 'documents';
export const PERM_JOBSITE = 'jobsiteSafety';

// Post Construction
export const PERM_SERVICE_WARRANTY = 'serviceWarranty';
export const PERM_CLOSE_OUT_DOCS = 'closeOutDocs';

// Payroll
export const PERM_PAYROLL = 'payroll';

// Administration
export const PERM_USER_MANAGEMENT = 'userManagement';
export const PERM_QUICKBOOKS = 'quickBooks';

// Assembly
export const PERM_ASSEMBLY_EDIT_ITEM = 'assemblyEditItem';

// Uses only in front
export const PERMISSIONS = [
  {
    key: 'database',
    i18nKey: 'database',
    perms: [
      {
        key: PERM_CONTACTS,
        i18nKey: 'contacts',
      },
      {
        key: PERM_HOURLY,
        i18nKey: 'hourly-rate',
      },
      {
        key: PERM_DB_BASE,
        i18nKey: 'base-level-db',
      },
      {
        key: PERM_DB_TEMPLATES,
        i18nKey: 'templates-types-for-assemblies',
      },

      {
        key: PERM_DB_CATALOG,
        i18nKey: 'perm-db-catalog',
      },

      {
        key: PERM_DB_MATERIALS,
        i18nKey: 'perm-db-materials',
      },

      {
        key: PERM_DB_LABOR,
        i18nKey: 'perm-db-labor',
      },
    ],
  },
  {
    key: PERM_ASSIGNMENTS,
    i18nKey: 'assignments',
  },
  {
    key: 'project_management',
    i18nKey: 'project-management',
    perms: [
      {
        key: PERM_STRUCTURE,
        i18nKey: 'structure-assemblies',
      },
      {
        key: PERM_FINANCIALS,
        i18nKey: 'financials',
      },
      {
        key: PERM_SUBMITTALL,
        i18nKey: 'submittal',
      },
      {
        key: PERM_CHANGE_ORDERS,
        i18nKey: 'change-orders',
      },
      {
        key: PERM_RFI,
        i18nKey: 'RFI',
      },
      {
        key: PERM_SCHEDULE_PERMITS_INSPECTIONS,
        i18nKey: 'schedule-permits-inspections',
      },
      {
        key: PERM_DOCUMENTS,
        i18nKey: 'documents',
      },
      {
        key: PERM_JOBSITE,
        i18nKey: 'jobsite-safety',
      },
    ],
  },
  {
    key: 'post_construction',
    i18nKey: 'post-construction',
    perms: [
      { key: PERM_SERVICE_WARRANTY, i18nKey: 'service-and-warranty' },
      { key: PERM_CLOSE_OUT_DOCS, i18nKey: 'close-out-docs' },
    ],
  },
  {
    key: PERM_PAYROLL,
    i18nKey: 'Payroll',
  },
  {
    key: 'administration',
    i18nKey: 'administration',
    perms: [
      {
        key: PERM_USER_MANAGEMENT,
        i18nKey: 'user-management',
      },
      {
        key: PERM_QUICKBOOKS,
        i18nKey: 'quickbooks',
      },
    ],
  },
  {
    key: 'assembly',
    i18nKey: 'assembly',
    perms: [
      {
        key: PERM_ASSEMBLY_EDIT_ITEM,
        i18nKey: 'edit-assembly-items',
      },
    ],
  },
];
