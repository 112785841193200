import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconAlert = (props) => {
  const { width = 32, height = 32, isError, rhombic } = props;

  return rhombic ? (
    <SvgIcon width={width} height={height} viewBox="0 0 64 64">
      <path
        d="M32 17l32 32-32 32L0 49l32-32zm0 5.657L5.657 49 32 75.343 58.343 49 32 22.657zM34 58v4h-4v-4h4zm0-22v18h-4V36h4z"
        transform="translate(0 -17)"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width} height={height} viewBox="0 0 32 32">
      {isError ? (
        <path
          d="M41.796 20c.53 0 1.039.21 1.414.586l8.204 8.204c.375.375.586.884.586 1.414v11.592c0 .53-.21 1.039-.586 1.414l-8.204 8.204c-.375.375-.884.586-1.414.586H30.204c-.53 0-1.039-.21-1.414-.586l-8.204-8.204c-.375-.375-.586-.884-.586-1.414V30.204c0-.53.21-1.039.586-1.414l8.497-8.497c.187-.188.442-.293.707-.293zm0 2H30.204L22 30.204v11.592L30.204 50h11.592L50 41.796V30.204L41.796 22zM37 41v2h-2v-2h2zm-1-12c.513 0 .936.386.993.883L37 30v8c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L35 38v-8c0-.552.448-1 1-1z"
          transform="translate(-540 -50) translate(520 30)"
        />
      ) : (
        <path
          d="M40 20c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-8c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h8zm0 2h-8c-5.43 0-9.848 4.327-9.996 9.72L22 32v8c0 5.43 4.327 9.848 9.72 9.996L32 50h8c5.43 0 9.848-4.327 9.996-9.72L50 40v-8c0-5.43-4.327-9.848-9.72-9.996L40 22zm2.718 9.304c.355.366.374.933.063 1.321l-.085.093-8.25 8c-.358.347-.91.374-1.299.08l-.093-.08-3.75-3.636c-.397-.385-.406-1.018-.022-1.415.355-.365.922-.402 1.319-.103l.095.082 3.054 2.961 7.554-7.325c.396-.384 1.03-.375 1.414.022z"
          transform="translate(-508 -50) translate(488 30)"
        />
      )}
    </SvgIcon>
  );
};

IconAlert.propTypes = simpleIconPropsTypes;
