import React, { useContext } from 'react';
import AlertSnackbar from '../../common/Dialogs/AlertSnackbar';

export const SnackbarContext = React.createContext(undefined);

export class SnackbarProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbars: [],
      count: 2,
    };
    this.index = 0;
    this.snackbars = new Map();
    this.methods = {
      push: this.push.bind(this),
      pushError: this.pushError.bind(this),
      close: this.close.bind(this),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const count = Number(props.count) || state.count;
    if (count !== state.count) {
      return { ...state, count };
    }
    return state;
  }

  dispatch() {
    const snackbars = [...this.snackbars.entries()].slice(0, this.state.count);
    this.setState({ ...this.state, snackbars });
  }

  push(dataOrTitle, text = undefined) {
    const data =
      typeof dataOrTitle === 'object' && dataOrTitle.title
        ? dataOrTitle
        : { title: String(dataOrTitle), text: String(text) };
    this.snackbars.set(`snackbar-${this.index++}`, data);
    this.dispatch();
  }

  pushError(err) {
    const { error, name, message } = err;
    this.push({ title: error || name, text: message, isError: true });
  }

  close(key) {
    this.snackbars.delete(key);
    this.dispatch();
  }

  render() {
    const value = {
      ...this.methods,
      snackbars: this.state.snackbars,
    };
    return <SnackbarContext.Provider value={value}>{this.props.children}</SnackbarContext.Provider>;
  }
}

export const SnackbarConsumer = () => (
  <SnackbarContext.Consumer>
    {(props) => {
      const { snackbars, close } = props;
      return snackbars.map(([key, value]) => <AlertSnackbar key={key} {...value} onClose={() => close(key)} />);
    }}
  </SnackbarContext.Consumer>
);

/**
 * use Snackbar Context
 * @return {{snackbars: any[], push: function(...args), pushError: function(err), close: function(key)}}
 */
export const useSnackbarContext = () => useContext(SnackbarContext);
