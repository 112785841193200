import { PERM_PAYROLL } from '../perms';

export const ROUTE_PAYROLL = {
  key: 'payroll',
  path: '/payroll',
  i18nKey: 'Payroll',
  icon: 'IconPayroll',
  permKey: PERM_PAYROLL,
};

export const ROUTE_PAYROLL_ALL_WEEKS = {
  key: 'all-weeks',
  path: `${ROUTE_PAYROLL.path}/all`,
  i18nKey: 'Payroll',
  icon: 'IconPayroll',
};

export const ROUTE_PIECEWORKS = {
  key: 'pieceworks',
  path: `${ROUTE_PAYROLL.path}/pieceworks`,
  i18nKey: 'project-pieceworks',
  icon: 'IconPayroll',
};
