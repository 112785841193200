import { ACCESS_EDIT, ACCESS_VIEW } from '../constants';

export function getStringAvatar(user) {
  if (!user) return 'UN';
  const { name, familyName, lastName } = user;

  return [
    (name && String(name)[0]) || 'U',
    ((familyName || lastName) && String(familyName || lastName)[0]) || 'N',
  ].join('');
}

export function canAccess(permissions, access, key) {
  return Boolean(permissions && permissions[key] >= access);
}

export const canEdit = (permissions, key) => canAccess(permissions || {}, ACCESS_EDIT, key);
export const canView = (permissions, key) => canAccess(permissions || {}, ACCESS_VIEW, key);
