import randomHex from './randomHex';

export default function jsonrpc(method, params) {
  return {
    jsonrpc: '2.0',
    method,
    params,
    id: randomHex(8),
  };
}
