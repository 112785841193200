export const ROUTE_HOME = {
  key: 'home',
  path: '/home',
  i18nKey: 'home',
  icon: 'IconDashboard',
};

export const ROUTE_ADDON = {
  key: 'addon',
  path: `/addon`,
  i18nKey: 'addon',
  icon: 'IconNarrative',
  disabled: !process.env.REACT_APP_PLUGIN_SCRIPT,
};
