import { ROUTE_ASSEMBLIES } from './assemblies';
import {
  ROUTE_ASSIGNMENT_TEMPLATES,
  ROUTE_BUILDING_MATERIALS,
  ROUTE_CATEGORIES,
  ROUTE_CONTACTS,
  ROUTE_DATABASE_MANAGEMENT,
  ROUTE_DOCUMENTS,
  ROUTE_EMAIL_TEMPLATE,
  ROUTE_FIXTURES,
  ROUTE_LABOR_COST_HOURLY_RATE,
  ROUTE_MANUFACTURERS,
  ROUTE_MARKETS,
  ROUTE_NARRATIVE_ITEMS,
  // ROUTE_PHASES,
  ROUTE_COST_CODE,
  ROUTE_SUPPLIERS,
} from './database';
import { ROUTE_ADDON, ROUTE_HOME } from './home';
import { ROUTE_PAYROLL } from './payroll';
import { ROUTE_PROJECTS } from './projects';
import { REPORTS_MENU, ROUTE_REPORTS } from './reports';
import { ROUTE_USERS_MANAGEMENT, ROUTE_USERS_ROLES } from './users';

export * from './assemblies';
export * from './database';
export * from './home';
export * from './payroll';
export * from './projects';
export * from './reports';
export * from './users';

export const ROUTE_LOGIN = { key: 'login', path: '/login', i18nKey: 'log-in', icon: 'IconArrow' };

export const APPLICATION_MENU = {
  rootMenu: [
    ROUTE_HOME,
    ROUTE_ADDON,
    ROUTE_PROJECTS,
    ROUTE_DATABASE_MANAGEMENT,
    ROUTE_ASSEMBLIES,
    ROUTE_USERS_MANAGEMENT,
    ROUTE_PAYROLL,
    ROUTE_REPORTS,
  ],
  sidebarMenu: {
    [ROUTE_USERS_MANAGEMENT.path]: [{ ...ROUTE_USERS_MANAGEMENT, i18nKey: 'users' }, ROUTE_USERS_ROLES],
    [ROUTE_DATABASE_MANAGEMENT.path]: [
      ROUTE_CONTACTS,
      ROUTE_DOCUMENTS,
      ROUTE_MARKETS,
      ROUTE_MANUFACTURERS,
      ROUTE_SUPPLIERS,
      ROUTE_CATEGORIES,
      ROUTE_BUILDING_MATERIALS,
      ROUTE_FIXTURES,
      {
        ...ROUTE_LABOR_COST_HOURLY_RATE,
        i18nKey: 'labor-costs',
      },
      // ROUTE_PHASES,
      ROUTE_COST_CODE,
      ROUTE_ASSIGNMENT_TEMPLATES,
      ROUTE_NARRATIVE_ITEMS,
      ROUTE_EMAIL_TEMPLATE,
    ],
    [ROUTE_REPORTS.path]: REPORTS_MENU,
  },
};

export const PLUGIN_MENU = {
  rootMenu: [ROUTE_PROJECTS],
};
