import { fade } from '@material-ui/core/styles/colorManipulator';
import colors from '../common/colors';
import mixins from '../common/mixins';
import typography from '../common/typography';

const overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        minWidth: process.env.REACT_APP_PLUGIN === 'true' ? 320 : 1360,
      },
      '*': {
        'scrollbar-color': `${colors.dark.background.paper} ${colors.dark.background.default}`,
        'scrollbar-width': 'thin',
      },
      '::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
        cursor: 'pointer',
      },
      '::-webkit-scrollbar:horizontal': {
        height: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: colors.dark.background.default,
        cursor: 'pointer',
      },
      '::-webkit-scrollbar-thumb': {
        background: colors.dark.background.context,
        cursor: 'pointer',

        '&:hover': {
          background: colors.dark.background.paperSecondary,
          cursor: 'pointer',
        },
      },
      '.pointer': {
        cursor: 'pointer',
      },
      '.pointer_link': {
        display: 'inline-flex',
        maxWidth: '100%',
        alignItems: 'center',
        gridColumnGap: '10px',
        textDecoration: 'none',
        color: 'white',
      },
      '.draggable': {
        cursor: 'move',
        transform: 'translate3d(0, 0, 0)',
      },
      '.unselectable': {
        ...mixins.unselectable,
      },
      '.sticky': {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 3,
      },
      '.fullWidth': {
        width: '100%',
      },
      '.relative': {
        position: 'relative',
      },
      '.center': { textAlign: 'center' },
      '.right': { textAlign: 'right' },
      '.showOnHover': { opacity: 0, transition: 'opacity 0.3s' },
      '.targetHover:hover .showOnHover': { opacity: 1 },
      '.ql-wys': {
        backgroundColor: colors.dark.background.input,
        color: colors.dark.text.secondary,
        '&:hover, &.focus': { borderColor: colors.primary.main },
        '&.white': { backgroundColor: colors.white, color: colors.black },

        '& .ql-toolbar': { backgroundColor: colors.dark.background.context, color: colors.dark.text.primary },
        '& .ql-toolbar button': { color: colors.dark.text.primary },
        '& .ql-picker-options': { backgroundColor: colors.dark.background.context },

        '& .ql-tooltip': {
          left: '0px !important',
          backgroundColor: colors.dark.background.context,
          color: colors.dark.text.primary,
          '& input[type="text"]': {
            backgroundColor: colors.dark.background.input,
            color: colors.dark.text.secondary,
          },
        },

        '& .ql-editor.ql-blank::before': { color: colors.dark.text.placeholder },
      },
      '.hover-document': {
        cursor: 'pointer',
        '&:hover': {
          color: colors.dark.text.primary,
        },
      },
    },
  },
  MuiButton: {
    root: {
      minWidth: 0,
      borderRadius: 12,
      boxShadow: 'none',
      padding: '14.5px 30px 15.5px',
      '&$disabled': {
        color: undefined,
      },
    },
    iconSizeMedium: {
      '& > *:first-child': {
        fontSize: 18,
      },
    },
    text: {
      padding: undefined,
    },
    outlined: {
      padding: '12.5px 28px 13.5px',
      borderWidth: 2,
      '&$disabled': {
        border: '2px solid',
      },
    },
    contained: {
      boxShadow: 'none',
      '&$disabled': {
        color: undefined,
        backgroundColor: undefined,
      },
    },
    outlinedPrimary: {
      color: colors.primary.light,
      borderWidth: 2,
      '&:hover': {
        borderWidth: 2,
      },
    },
    outlinedSecondary: {
      borderWidth: 2,
      '&:hover': {
        borderWidth: 2,
      },
    },
    textSizeLarge: {
      fontSize: '1rem',
    },
    containedSizeLarge: {
      padding: '19px 30px 22px',
      fontSize: typography.body1.fontSize,
    },
    outlinedSizeLarge: {
      padding: '18px 30px 19px',
      fontSize: typography.body1.fontSize,
    },
    textSizeSmall: {
      padding: '6px 12px 7px',
    },
    containedSizeSmall: {
      padding: '6px 12px 7px',
    },
    outlinedSizeSmall: {
      padding: '4px 10px 5px',
    },
  },
  MuiRadio: {
    colorPrimary: {
      color: colors.primary.light,
      '&$checked': {
        color: colors.white,
      },
    },
  },
  MuiCheckbox: {
    root: {
      color: null,
    },
    colorPrimary: {
      '&$checked': {
        color: colors.dark.text.primary,
      },
    },
  },
  MuiBreadcrumbs: {
    root: {
      color: colors.dark.text.disabled,
      '& a': {
        color: colors.dark.text.notActive,
      },
    },
    separator: {
      marginLeft: 10,
      marginRight: 10,
      lineHeight: 1,
    },
  },
  MuiIconButton: {
    label: {
      '& > .tsvg': { minWidth: 18, minHeight: 18, textAlign: 'center' },
    },
    colorPrimary: {
      color: colors.primary.light,
    },
  },
  MuiTableRow: {
    root: {
      '&$selected, &$selected:hover': {
        backgroundColor: colors.dark.action.tableSelected,
      },
    },
  },
  MuiTableSortLabel: {
    root: {
      color: colors.dark.table.tHeadText,
      '& > svg': {
        marginLeft: 5,
      },
    },
  },
  MuiSwitch: {
    switchBase: {
      color: colors.dark.text.notActive,
      '&$checked': {
        color: colors.white,
        '&:hover': {
          backgroundColor: undefined,
        },
      },
      '&$checked + $track': {
        backgroundColor: colors.success.main,
        opacity: 1,
      },
    },
    colorPrimary: {
      '&$checked': {
        color: colors.primary.light,
      },
    },
  },
  MuiChip: {
    root: {
      height: 28,
      backgroundColor: colors.dark.background.paperSecondary,
      maxWidth: '100%',
    },
    outlined: {
      '& $icon': {
        marginLeft: 8,
        marginRight: -4,
      },
    },
    outlinedPrimary: {
      color: colors.primary.light,
    },
    outlinedSecondary: {
      borderColor: fade(colors.secondary.main, 0.2),
    },
    sizeSmall: {
      height: 18,
      borderRadius: 4,
      minWidth: 0,
    },
    label: {
      lineHeight: 1.2,
      paddingBottom: 1,
    },
    labelSmall: {
      paddingLeft: 7,
      paddingRight: 7,
    },
    icon: {
      color: 'inherit',
    },
  },
  MuiGrid: {
    'grid-xs-auto': {
      width: 'auto',
    },
    item: {
      '&.giaction': { minWidth: 30 },
    },
  },
  MuiDivider: {
    light: {
      backgroundColor: colors.dark.dividerSecondary,
    },
  },
  MuiLinearProgress: {
    root: {
      height: 6,
      borderRadius: 3,
      backgroundColor: colors.dark.background.paperSecondary,
    },
    colorPrimary: {
      backgroundColor: undefined,
    },
    colorSecondary: {
      backgroundColor: undefined,
    },
  },
  MuiAvatar: {
    root: {
      fontWeight: typography.fontWeightExtraBold,
      textTransform: 'uppercase',
      fontSize: typography.body1.fontSize,
      wordWrap: 'break-word',
    },
    colorDefault: {
      backgroundColor: colors.dark.text.primary,
      color: colors.dark.background.default,
    },
    rounded: {
      borderRadius: 10,
    },
  },
  MuiTypography: {
    colorPrimary: {
      color: colors.primary.light,
    },
  },
  MuiLink: {
    root: {
      outline: 'none',
    },
    button: {
      fontSize: 'inherit',
    },
  },
  MuiPopover: {
    paper: {
      backgroundColor: colors.dark.background.context,
      borderRadius: 12,
      minWidth: 160,
      padding: '5px 0',
      '.datepicker > &': {
        backgroundColor: colors.dark.background.paperSecondary,
        padding: 0,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: colors.dark.background.paper,
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: null,
    },
  },
  MuiListItemIcon: {
    root: {
      color: 'inherit',
    },
  },
  MuiSvgIcon: {
    root: {
      verticalAlign: 'top',
    },
  },
  MuiTooltip: {
    tooltip: {
      padding: '20px 25px',
      backgroundColor: colors.dark.background.context,
      borderRadius: 16,
      maxWidth: 500,
      whiteSpace: 'normal',
      '&.small': {
        padding: '10px 15px',
        borderRadius: 12,
      },
    },
    arrow: {
      color: colors.dark.background.context,
    },
  },
  MuiToggleButtonGroup: {
    root: {
      padding: 6,
      backgroundColor: colors.dark.background.default,
      borderRadius: 12,
    },
    groupedHorizontal: {
      '&:not(:last-child)': {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        marginRight: 5,
      },
      '&:not(:first-child)': {
        borderLeft: 'none',
        marginLeft: 0,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
    },
  },
  MuiToggleButton: {
    root: {
      border: 'none',
      borderRadius: 8,
      color: colors.primary.main,
      padding: 9,
      '&$selected': {
        '.tbg-primary &': {
          backgroundColor: colors.primary.main,
        },
      },
    },
    sizeSmall: {
      padding: 3,
      borderRadius: '6px !important',
    },
    sizeLarge: {
      padding: '20px 15px',
      minWidth: 60,
    },
  },
  MuiInputLabel: {
    filled: {
      color: colors.dark.text.placeholder,
      transform: 'translate(28px, 23px) scale(1)',
      '&$shrink': {
        transform: 'translate(30px, 14px) scale(0.75)',
        '&:not($error)': {
          color: colors.dark.text.disabled,
        },
        '&$marginDense': {
          transform: 'translate(18px, 9px) scale(0.75)',
        },
      },
      '&$marginDense': {
        fontSize: typography.body2.fontSize,
        transform: 'translate(18px, 17px) scale(1)',
      },
    },
  },
  MuiInputBase: {
    root: {
      '&$disabled': { color: undefined },
    },
  },
  MuiFilledInput: {
    root: {
      borderRadius: 16,
      backgroundColor: colors.dark.background.input,
      border: `2px solid ${colors.dark.background.input}`,
      color: colors.dark.text.secondary,
      minWidth: 70,
      '.square > &': {
        borderRadius: 0,
      },
      '&:not($focused) > svg:first-child': {
        color: colors.dark.text.disabled,
      },
      '&:hover': {
        borderColor: colors.primary.main,
        backgroundColor: colors.dark.background.input,
      },
      '&$focused': {
        borderColor: colors.primary.main,
        backgroundColor: colors.dark.background.input,
      },
      '&$disabled': {
        backgroundColor: colors.dark.background.paperSecondary,
        borderColor: colors.dark.background.paperSecondary,
      },
    },
    input: {
      paddingLeft: 28,
      height: '1.438em',
      '&::-webkit-calendar-picker-indicator': {
        filter: 'invert(1)',
      },
    },
    inputMarginDense: {
      paddingTop: 19,
      paddingLeft: 15,
      paddingBottom: 3,
      fontSize: typography.body2.fontSize,
    },
    inputHiddenLabel: {
      '&$inputMarginDense': {
        paddingLeft: 15,
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    inputAdornedStart: {
      paddingLeft: 10,
      '&$inputMarginDense': {
        paddingLeft: 10,
      },
    },
    inputAdornedEnd: {
      paddingLeft: 27,
    },
    marginDense: {
      borderRadius: 12,
    },
    multiline: {
      paddingLeft: 28,
    },
    inputMultiline: {
      height: 'auto',
      cursor: 'auto',
    },
  },
  MuiOutlinedInput: {
    root: {
      color: colors.primary.light,
      '.square > &': {
        borderRadius: 0,
      },
      '.color-default &': {
        color: colors.dark.text.primary,
      },
      '&:not($focused) > svg:first-child': {
        color: colors.dark.text.disabled,
      },
      '&:hover': {
        '& $notchedOutline': {
          borderColor: colors.primary.light,
          '.color-default &': {
            borderColor: colors.dark.text.primary,
          },
        },
        '&$colorSecondary  $notchedOutline': {
          borderColor: colors.secondary.main,
        },
      },
      '&$focused': {
        '.color-default & $notchedOutline': {
          borderColor: colors.dark.text.primary,
        },
      },
    },
    notchedOutline: {
      borderColor: colors.primary.main,
      borderWidth: 2,
      '.color-default &': {
        borderColor: fade(colors.dark.text.primary, 0.28),
      },
    },
    colorSecondary: {
      color: colors.secondary.main,
      '& $notchedOutline': {
        borderColor: colors.secondary.dark,
      },
    },
    input: {
      height: '1.438em',
      '&::placeholder': {
        color: colors.dark.text.placeholder,
      },
    },
    inputAdornedEnd: {
      paddingRight: 10,
    },
    inputAdornedStart: {
      paddingLeft: 10,
    },
    inputMarginDense: {
      paddingTop: 13.5,
      paddingBottom: 13.5,
      fontSize: typography.body2.fontSize,
    },
    marginDense: {
      borderRadius: 12,
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: undefined,
      },
    },
    selectMenu: {
      height: '1.438em',
      minWidth: 150,
    },
    filled: {
      '&$filled': {
        paddingRight: 38,
      },
    },
    outlined: {
      '&$outlined': {
        paddingRight: 32,
        paddingLeft: 20,
      },
    },
    iconFilled: {
      right: 12,
    },
  },
  MuiAutocomplete: {
    paper: {
      backgroundColor: colors.dark.background.paperSecondary,
    },
    root: {
      '& > .multiHiddenLabel > [class*="MuiFilledInput-root"]': {
        paddingTop: 10,
        paddingBottom: 9,
        '& $input': { padding: '9px 4px' },
      },
    },
    inputRoot: {
      '&[class*="MuiFilledInput-root"]': {
        paddingTop: 25,
        paddingLeft: 24,
        paddingBottom: 6,
        '& $input': { padding: '3px 4px' },
      },
      '&[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"]': {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        '& $input': {
          padding: '15px 0 0 10px',
          '&[class*="MuiFilledInput-inputHiddenLabel"]': {
            paddingTop: 7.5,
            paddingBottom: 7.5,
          },
        },
      },
    },
  },
};

export default overrides;
