import {
  PERM_CONTACTS,
  PERM_DB_BASE,
  PERM_DB_CATALOG,
  PERM_DB_LABOR,
  PERM_DB_MATERIALS,
  PERM_DB_TEMPLATES,
} from '../perms';

export const ROUTE_DATABASE_MANAGEMENT = {
  key: 'database',
  path: '/database',
  i18nKey: 'Database-Management',
  icon: 'IconDataBase',
  permKey: [PERM_DB_BASE, PERM_CONTACTS, PERM_DB_TEMPLATES, PERM_DB_CATALOG, PERM_DB_LABOR, PERM_DB_MATERIALS],
};

export const ROUTE_CATEGORIES = {
  key: 'categories',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/categories`,
  i18nKey: 'categories',
  icon: 'IconCategories',
  totalApi: 'category.getCategoryTotal',
  permKey: PERM_DB_CATALOG,
};

export const ROUTE_MANUFACTURERS = {
  key: 'manufacturers',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/manufacturers`,
  i18nKey: 'manufacturers',
  icon: 'IconManufacturers',
  totalApi: 'manufacturer.getTotal',
  permKey: PERM_DB_CATALOG,
};

export const ROUTE_SUPPLIERS = {
  key: 'suppliers',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/suppliers`,
  i18nKey: 'suppliers',
  icon: 'IconSuppliers',
  totalApi: 'supplier.getTotal',
  permKey: PERM_DB_CATALOG,
};

export const ROUTE_MARKETS = {
  key: 'markets',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/markets`,
  i18nKey: 'markets',
  icon: 'IconMarkets',
  totalApi: 'market.getTotal',
  permKey: PERM_DB_CATALOG,
};

export const ROUTE_DOCUMENTS = {
  key: 'documents',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/documents`,
  i18nKey: 'documents',
  icon: 'IconDocuments',
  totalApi: 'document.getDocumentTotal',
  permKey: PERM_DB_BASE,
};

export const ROUTE_LABOR_COST = {
  key: 'labor-costs',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/labor-costs`,
  i18nKey: 'labor-costs',
  icon: 'IconLaborCost',
  totalApi: 'laborCost.getTotal',
  permKey: PERM_DB_LABOR,
};

export const ROUTE_LABOR_COST_HOURLY_RATE = {
  key: 'hourly-rate',
  path: `${ROUTE_LABOR_COST.path}/hourly-rate`,
  i18nKey: 'hourly',
  icon: 'IconLaborCost',
  permKey: PERM_DB_LABOR,
};
export const ROUTE_LABOR_COST_PIECE_WORK = {
  key: 'piece-work',
  path: `${ROUTE_LABOR_COST.path}/piece-work`,
  i18nKey: 'piecework',
  icon: 'IconLaborCost',
  permKey: PERM_DB_LABOR,
};

export const ROUTE_BUILDING_MATERIALS = {
  key: 'building-materials',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/building-materials`,
  i18nKey: 'building-materials',
  icon: 'IconBuildingMaterials',
  totalApi: 'material.getTotal',
  permKey: PERM_DB_MATERIALS,
};

export const ROUTE_FIXTURES = {
  key: 'fixtures',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/fixtures-and-specialties`,
  i18nKey: 'fixtures-and-specialties',
  icon: 'IconFixtures',
  totalApi: 'fixture.getTotal',
  permKey: PERM_DB_MATERIALS,
};

export const ROUTE_CONTACTS = {
  key: 'contacts',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/contacts`,
  i18nKey: 'contacts',
  icon: 'IconContacts',
  permKey: PERM_CONTACTS,
};

export const ROUTE_CONTACTS_INDIVIDUAL = {
  key: 'individual',
  path: `${ROUTE_CONTACTS.path}/individual`,
  i18nKey: 'individual',
  icon: 'IconContacts',
  permKey: PERM_CONTACTS,
};
export const ROUTE_CONTACTS_EMPLOYEE = {
  key: 'employee',
  path: `${ROUTE_CONTACTS.path}/employee`,
  i18nKey: 'employee',
  icon: 'IconContacts',
  permKey: PERM_CONTACTS,
};

// export const ROUTE_PHASES = {
//   key: 'phases',
//   path: `${ROUTE_DATABASE_MANAGEMENT.path}/phases`,
//   i18nKey: 'phases',
//   icon: 'IconPhases',
//   permKey: PERM_DB_LABOR,
// };

export const ROUTE_COST_CODE = {
  key: 'cost-code',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/cost-code`,
  i18nKey: 'cost-codes',
  icon: 'IconPhases',
  permKey: PERM_DB_LABOR,
};

export const ROUTE_COST_CODE_PHASES = {
  key: 'phases',
  path: `${ROUTE_COST_CODE.path}/phases`,
  i18nKey: 'phases',
  icon: 'IconPhases',
  permKey: PERM_DB_LABOR,
};

export const ROUTE_ASSIGNMENT_TEMPLATES = {
  key: 'assignment-templates',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/assignment-templates`,
  i18nKey: 'assignment-templates',
  icon: 'IconOverview',
  totalApi: 'taskTemplate.getTotal',
  permKey: PERM_DB_TEMPLATES,
};

export const ROUTE_NARRATIVE_ITEMS = {
  key: 'narrative-items',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/narrative-items`,
  i18nKey: 'narrative-items',
  icon: 'IconNarrative',
  permKey: PERM_DB_TEMPLATES,
};

export const ROUTE_EMAIL_TEMPLATE = {
  key: 'email-templates',
  path: `${ROUTE_DATABASE_MANAGEMENT.path}/email-templates`,
  i18nKey: 'email-templates',
  icon: 'IconEmail',
  totalApi: 'emailTemplate.getTotal',
  permKey: PERM_DB_TEMPLATES,
};
