import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { AuthContextProvider } from './components/contexts/AuthContext';
import { SnackbarConsumer, SnackbarProvider } from './components/contexts/SnackbarContext';
import { I18nProvider } from './services/i18n';
import { darkTheme } from './themes';
import Router from './Router';

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <I18nProvider>
        <SnackbarProvider>
          <AuthContextProvider>
            <Router />
            <SnackbarConsumer />
          </AuthContextProvider>
        </SnackbarProvider>
      </I18nProvider>
    </ThemeProvider>
  );
}

export default App;
