import axios from 'axios';
import ApiClient from './apiClient';
import HttpAdapter from './httpAdapter';
import jsonrpc from './jsonrpc';
import ioClient from './ioClient';

const api = new ApiClient(
  new HttpAdapter(
    axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
    })
  )
);

api.toJsonRpc = jsonrpc;

export const rpc = {
  call: (method, params) => api.jsonRpc(jsonrpc(method, params)),
  batchCall: (obj) => {
    const data = (Array.isArray(obj) ? obj : Object.entries(obj)).map(([method, params]) => jsonrpc(method, params));
    return api.jsonRpc(data);
  },
};

export const useApi = () => rpc;

const apiStore = {};

export const getRpcApi = (...methods) => {
  return methods.map((method) => {
    if (!apiStore[method]) {
      apiStore[method] = (...params) => api.jsonRpc(jsonrpc(method, params.length > 1 ? params : params[0]));
    }
    return apiStore[method];
  });
};

export const wsClient = new ioClient(process.env.REACT_APP_API_URL);

export default api;
