import { PERM_ASSIGNMENTS, PERM_DOCUMENTS, PERM_RFI, PERM_SUBMITTALL } from '../perms';

export const ROUTE_PROJECTS = {
  key: 'projects',
  path: '/projects',
  i18nKey: 'projects',
  icon: 'IconProjects',
  // permKey: PERM_DB_TEMPLATES,
};

export const ROUTE_PROJECTS_OVERVIEW = {
  key: 'project-overview',
  path: `${ROUTE_PROJECTS.path}/:id`,
  i18nKey: 'project-overview',
  icon: 'IconOverview',
  component: 'ProjectOverview',
  template: true,
};

export const ROUTE_PROJECTS_STRUCTURE = {
  key: 'project-structure',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/structure`,
  i18nKey: 'project-scope',
  icon: 'IconScope',
  component: 'ProjectStructure',
  template: true,
};

export const ROUTE_PROJECTS_ASSIGNMENT = {
  key: 'assignment-board',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/assignment`,
  i18nKey: 'assignment-board',
  icon: 'IconAssignments',
  component: 'AssignmentBoard',
  template: true,
  permKey: PERM_ASSIGNMENTS,
};

export const ROUTE_PROJECTS_SPECIFIC_QUOTES = {
  key: 'quotes',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/quotes`,
  i18nKey: 'js-quotes',
  icon: 'IconQuote',
  component: 'Quotes',
};

export const ROUTE_PROJECTS_SUBMITTALS = {
  key: 'submittals',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/submittals`,
  i18nKey: 'submittals',
  icon: 'IconSubmittals',
  component: 'Submittals',
  template: true,
  permKey: PERM_SUBMITTALL,
};

export const ROUTE_PROJECTS_DOCUMENTS = {
  key: 'documents',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/documents`,
  i18nKey: 'project-documents',
  icon: 'IconDocumentsMulti',
  component: 'ProjectDocuments',
  permKey: PERM_DOCUMENTS,
};

export const ROUTE_PROJECTS_HISTORY = {
  key: 'history',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/history`,
  i18nKey: 'project-history',
  icon: 'IconHistory',
  component: 'ProjectHistory',
};

export const ROUTE_PROJECTS_EMAILS = {
  key: 'emails',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/emails`,
  i18nKey: 'project-emails',
  icon: 'IconMail',
  component: 'ProjectEmails',
};

export const ROUTE_SERVICE_AND_WARRANTY = {
  key: 'service-warranty',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/service-warranty`,
  i18nKey: 'service-and-warranty',
  icon: 'IconCertificate',
  component: 'ServiceAndWarranty',
};

export const ROUTE_REQUEST_FOR_INFO = {
  key: 'request-info',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/request-info`,
  i18nKey: 'request-for-information',
  icon: 'IconRequestInfo',
  component: 'RequestForInformation',
  permKey: PERM_RFI,
};

export const PROJECT_ROUTE_LABOR_COST_PIECEWORK = {
  key: 'piece-work',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/piece-work`,
  i18nKey: 'piecework',
  icon: 'IconLaborCost',
  component: 'LaborCost',
};

export const PROJECT_ROUTE_LABOR_COST_HOURLY_RATE = {
  key: 'labor-costs',
  path: `${ROUTE_PROJECTS_OVERVIEW.path}/labor-costs`,
  pathArr: [`${ROUTE_PROJECTS_OVERVIEW.path}/labor-costs`, PROJECT_ROUTE_LABOR_COST_PIECEWORK.path],
  i18nKey: 'labor-costs',
  icon: 'IconLaborCost',
  component: 'LaborCost',
};

export const PROJECT_MENU = [
  ROUTE_PROJECTS_OVERVIEW,
  ROUTE_PROJECTS_ASSIGNMENT,
  ROUTE_PROJECTS_STRUCTURE,
  ROUTE_PROJECTS_STRUCTURE,
  ROUTE_PROJECTS_SUBMITTALS,
  ROUTE_REQUEST_FOR_INFO,
  ROUTE_PROJECTS_SPECIFIC_QUOTES,
  ROUTE_SERVICE_AND_WARRANTY,
  ROUTE_PROJECTS_DOCUMENTS,
  ROUTE_PROJECTS_EMAILS,
  PROJECT_ROUTE_LABOR_COST_HOURLY_RATE,
  ROUTE_PROJECTS_HISTORY,
];
