import React, { Suspense, useEffect } from 'react';
import Loader from './components/common/Loader';
import { useAuth } from './components/contexts/AuthContext';
import { getRpcApi } from './services/api';

const preparePermissions = (user) => {
  const perms = {};
  for (const r of user.Roles || []) {
    const rolePerms = r.permissions || {};
    for (const p of Object.keys(rolePerms)) {
      if (!perms[p]) {
        perms[p] = rolePerms[p];
      } else if (perms[p] < rolePerms[p]) {
        perms[p] = rolePerms[p];
      }
    }
  }
  return perms;
};

const RouteSwitch = React.lazy(() =>
  process.env.REACT_APP_PLUGIN === 'true' ? import('./RouterPlugin') : import('./RouterMain.js')
);

const AppRouter = () => {
  const { isAuthenticated, pendingUser, pending, setContext } = useAuth();
  const [getCurrentUser] = getRpcApi('user.getCurrentUser');

  useEffect(() => {
    if (isAuthenticated) {
      getCurrentUser()
        .then((user) => {
          setContext({ user, permissions: preparePermissions(user), pendingUser: false });
        })
        .catch((e) => {
          console.error(e);
          setContext({ pendingUser: false });
        });
    }
    //eslint-disable-next-line
  }, [isAuthenticated, setContext]);

  return pending || (isAuthenticated && pendingUser) ? (
    <Loader fixed open />
  ) : (
    <Suspense fallback={<Loader fixed open />}>
      <RouteSwitch isAuthenticated />
    </Suspense>
  );
};

export default AppRouter;
