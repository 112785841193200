import React, { useContext, useEffect, useState } from 'react';
import translation from './translations/en.json';

function replaceValues(source, values) {
  if (values) {
    return Object.keys(values).reduce((str, key) => {
      return str.replace(`{${key}}`, values[key]);
    }, String(source));
  }
  return source;
}

export const i18nContextInitialValue = {
  currentLanguage: 'en',
  translation,
  changeLanguage: () => {},
  trans: () => {},
  plurals: () => {},
};
const I18nContext = React.createContext(i18nContextInitialValue);

export function useTrans() {
  return useContext(I18nContext);
}

export function I18nProvider({ children }) {
  const [state, setState] = useState(i18nContextInitialValue);

  useEffect(() => {
    const changeLanguage = (currentLanguage) => {
      import(`./translations/${currentLanguage}.json`)
        .then((translation) => {
          setState((state) => ({ ...state, translation, currentLanguage }));
        })
        .catch(console.error);
    };

    const trans = (key, values) => {
      if (!state.translation[key]) console.error('i18n. No such key - ', key);

      const text = state.translation[key] || key;
      return values ? replaceValues(text, values) : text;
    };

    const plurals = (val, ...keys) => {
      return val === 1 ? trans(keys[0]) : trans(keys[1] || keys[0]);
    };

    setState((state) => ({ ...state, changeLanguage, trans, plurals }));
  }, [state.translation]);

  return <I18nContext.Provider value={state}>{children}</I18nContext.Provider>;
}
