import { createMuiTheme } from '@material-ui/core';
import typography from '../common/typography';
import mixins from '../common/mixins';
import overrides from './overrides';
import palette from './pallete';

const darkTheme = createMuiTheme({
  direction: 'ltr',
  typography,
  palette,
  overrides,
  mixins,
  shape: {
    borderRadius: 16,
    borderRadiusAlt: 12,
  },
});

export default darkTheme;
