import React from 'react';
import 'fontsource-roboto';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';

function bootstrapReactApp() {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

if (process.env.REACT_APP_PLUGIN === 'true' && window.Office) {
  window.Office.onReady(bootstrapReactApp);
} else {
  bootstrapReactApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
