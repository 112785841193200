import colors from '../common/colors';

const palette = {
  type: 'dark',
  common: {
    black: colors.black,
    white: colors.white,
  },
  primary: colors.primary,
  secondary: colors.secondary,
  error: colors.error,
  warning: colors.warning,
  info: colors.info,
  success: colors.success,
  text: colors.dark.text,
  background: colors.dark.background,
  divider: colors.dark.divider,
  dividerSecondary: colors.dark.dividerSecondary,
  action: colors.dark.action,
  table: colors.dark.table,
  outlinedBtn: colors.dark.outlinedBtn,
  emailHint: colors.dark.text.success,
};

export default palette;
