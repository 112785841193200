export const PROJECT_STATUS_PRE_BID = 'pre-bid';
export const PROJECT_STATUS_DEAD = 'dead';
export const PROJECT_STATUS_BID = 'bid';
export const PROJECT_STATUS_NEGOTIATION = 'negotiation';
export const PROJECT_STATUS_RE_BID = 're-bid';
export const PROJECT_STATUS_AWARDED = 'awarded';
export const PROJECT_STATUS_UNDER_CONSTRUCTION = 'under-construction';
export const PROJECT_STATUS_WARRANTY = 'warranty';
export const PROJECT_STATUS_ARCHIVE = 'archive';
export const PROJECT_STATUS_TEMPLATE = 'TEMPLATE';

export const PROJECT_WARRANTY_MONTHS = 12;

export const PROJECT_SGROUP_TEMPLATE = 'template';
export const PROJECT_SGROUP_PRE_CONSTRACTION = 'preConstruction';
export const PROJECT_SGROUP_CONSTRACTION = 'construction';
export const PROJECT_SGROUP_WARRANTY = 'warranty';
export const PROJECT_SGROUP_ARCHIVED = 'archived';

export const projectStatusOptions = [
  PROJECT_STATUS_PRE_BID,
  PROJECT_STATUS_BID,
  PROJECT_STATUS_NEGOTIATION,
  PROJECT_STATUS_RE_BID,
  PROJECT_STATUS_AWARDED,
  PROJECT_STATUS_UNDER_CONSTRUCTION,
  PROJECT_STATUS_WARRANTY,
  PROJECT_STATUS_ARCHIVE,
  PROJECT_STATUS_DEAD,
];

export const PROJECT_STATUS_GROUP = {
  [PROJECT_SGROUP_TEMPLATE]: [PROJECT_STATUS_TEMPLATE],
  [PROJECT_SGROUP_PRE_CONSTRACTION]: [
    PROJECT_STATUS_PRE_BID,
    PROJECT_STATUS_BID,
    PROJECT_STATUS_NEGOTIATION,
    PROJECT_STATUS_RE_BID,
  ],
  [PROJECT_SGROUP_CONSTRACTION]: [PROJECT_STATUS_AWARDED, PROJECT_STATUS_UNDER_CONSTRUCTION],
  [PROJECT_SGROUP_WARRANTY]: [PROJECT_STATUS_WARRANTY],
  [PROJECT_SGROUP_ARCHIVED]: [PROJECT_STATUS_ARCHIVE, PROJECT_STATUS_DEAD],
};

export function getStatusGroup(status) {
  switch (status) {
    case PROJECT_STATUS_ARCHIVE:
    case PROJECT_STATUS_DEAD:
      return PROJECT_SGROUP_ARCHIVED;
    case PROJECT_STATUS_WARRANTY:
      return PROJECT_SGROUP_WARRANTY;
    case PROJECT_STATUS_AWARDED:
    case PROJECT_STATUS_UNDER_CONSTRUCTION:
      return PROJECT_SGROUP_CONSTRACTION;
    default:
      return PROJECT_SGROUP_PRE_CONSTRACTION;
  }
}

export const PROJECT_PHASE_NOT_STARTED = 'not-started';
export const PROJECT_PHASE_STARTED = 'started';
export const PROJECT_PHASE_COMPLETED = 'completed';
export const PROJECT_PHASE_SOME_STARTED = 'some-started';
export const PROJECT_PHASE_SOME_COMPLETED = 'some-completed';

export const projectPhaseStates = [PROJECT_PHASE_NOT_STARTED, PROJECT_PHASE_STARTED, PROJECT_PHASE_COMPLETED];

export const PHASE_UNDERGROUND = 'UNDERGROUND';
export const PHASE_ROUGH = 'ROUGH';
export const PHASE_TRIM = 'TRIM';

export const PHASES = [PHASE_UNDERGROUND, PHASE_ROUGH, PHASE_TRIM];

export const PHASES_GROUPING = {
  [PHASE_TRIM]: 0,
  [PHASE_ROUGH]: 0,
  [PHASE_UNDERGROUND]: 0,
};

export function getProgressInfo(summary, statusGroup, masterSummary) {
  if (!summary) return {};

  if (statusGroup === PROJECT_SGROUP_PRE_CONSTRACTION) {
    const totalTasks = Number(summary.totalTasks) || 0;
    const doneTasks = Number(summary.doneTasks) || 0;

    return { startLabel: `${doneTasks}/${totalTasks}`, value: totalTasks > 0 ? (doneTasks * 100) / totalTasks : 0 };
  }
  const totalPieceworks = masterSummary?.pieceWorksCount || PHASES_GROUPING;
  const payedPieceWorksGrouping = masterSummary?.payedPieceworks || PHASES_GROUPING;

  return {
    UG: {
      startLabel: 'UG',
      value:
        totalPieceworks && totalPieceworks[PHASE_UNDERGROUND] > 0
          ? (Number(payedPieceWorksGrouping[PHASE_UNDERGROUND]) * 100) / totalPieceworks[PHASE_UNDERGROUND]
          : 0,
    },
    RG: {
      startLabel: 'RG',
      value:
        totalPieceworks && totalPieceworks[PHASE_ROUGH] > 0
          ? (Number(payedPieceWorksGrouping[PHASE_ROUGH]) * 100) / totalPieceworks[PHASE_ROUGH]
          : 0,
    },
    TRM: {
      startLabel: 'TRM',
      value:
        totalPieceworks && totalPieceworks[PHASE_TRIM] > 0
          ? (Number(payedPieceWorksGrouping[PHASE_TRIM]) * 100) / totalPieceworks[PHASE_TRIM]
          : 0,
    },
  };
}

export const TASK_STATUS_NEW = 'NEW';
export const TASK_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const TASK_STATUS_AWAITING_RESPONSE = 'AWAITING_RESPONSE';
export const TASK_STATUS_DONE = 'DONE';
export const TASK_WARNING_DAYS = 5;
export const TASK_ERROR_DAYS = 2;

export const TASK_STATUSES_COLORS = {
  [TASK_STATUS_NEW]: 'default',
  [TASK_STATUS_IN_PROGRESS]: 'secondary',
  [TASK_STATUS_AWAITING_RESPONSE]: 'warning',
  [TASK_STATUS_DONE]: 'success',
};

export const ORDER_STATUS_RECEIVED = 'RECEIVED';
export const ORDER_STATUS_AWAIT_REVISION = 'AWAITING_REVISION';
export const ORDER_STATUS_AWAIT_EXECUTION = 'AWAITING_EXECUTION';
export const ORDER_STATUS_EXECUTED = 'EXECUTED';

export const ORDER_STATUSES_COLORS = {
  [ORDER_STATUS_RECEIVED]: 'notActive',
  [ORDER_STATUS_AWAIT_REVISION]: 'default',
  [ORDER_STATUS_AWAIT_EXECUTION]: 'warning',
  [ORDER_STATUS_EXECUTED]: 'success',
};

export const ALTERNATIVE_STATUS_PENDING = 'PENDING';
export const ALTERNATIVE_STATUS_APPROVED = 'APPROVED';
export const ALTERNATIVE_STATUS_DENIED = 'DENIED';
export const ALTERNATIVE_STATUS_ARCHIVED = 'ARCHIVED';

export const ALTERNATIVE_STATUS_COLORS = {
  [ALTERNATIVE_STATUS_PENDING]: 'default',
  [ALTERNATIVE_STATUS_DENIED]: 'warning',
  [ALTERNATIVE_STATUS_APPROVED]: 'success',
};
export const BID_STATUS_DRAFT = 'DRAFT';
export const BID_STATUS_SUBMITTED = 'SUBMITTED';
export const BID_STATUS_CONTRACTED = 'CONTRACTED';
export const BID_STATUS_ARCHIVED = 'ARCHIVED';
export const BID_STATUS_DEACTIVATED = 'DEACTIVATED';
export const BID_STATUS_COLORS = {
  [BID_STATUS_ARCHIVED]: 'default',
  [BID_STATUS_DEACTIVATED]: 'notActive',
  [BID_STATUS_DRAFT]: 'warning',
  [BID_STATUS_SUBMITTED]: 'success',
  [BID_STATUS_CONTRACTED]: 'success',
};

export const CHANGE_ORDER_PROPOSAL_STATUS_DRAFT = 'DRAFT';
export const CHANGE_ORDER_PROPOSAL_STATUS_SUBMITTED = 'SUBMITTED';
export const CHANGE_ORDER_PROPOSAL_STATUS_APPROVED = 'APPROVED';
export const CHANGE_ORDER_PROPOSAL_STATUS_PARTIAL_APPROVAL = 'PARTIAL_APPROVAL';
export const CHANGE_ORDER_PROPOSAL_STATUS_EXECUTED = 'EXECUTED';
export const CHANGE_ORDER_PROPOSAL_STATUS_PARTIAL_EXECUTION = 'PARTIAL_EXECUTION';

export const CHANGE_ORDER_PROPOSAL_STATUS_COLORS = {
  [CHANGE_ORDER_PROPOSAL_STATUS_DRAFT]: 'notActive',
  [CHANGE_ORDER_PROPOSAL_STATUS_PARTIAL_EXECUTION]: 'secondary',
  [CHANGE_ORDER_PROPOSAL_STATUS_APPROVED]: 'success',
  [CHANGE_ORDER_PROPOSAL_STATUS_SUBMITTED]: 'primary',
  [CHANGE_ORDER_PROPOSAL_STATUS_PARTIAL_APPROVAL]: 'success',
  [CHANGE_ORDER_PROPOSAL_STATUS_EXECUTED]: 'secondary',
};

export const CHANGE_ORDER_STATUS_EXPECTED = 'EXPECTED';
export const CHANGE_ORDER_STATUS_RECEIVED = 'RECEIVED';
export const CHANGE_ORDER_STATUS_REVIEWED_CORRECT = 'REVIEWED_CORRECT';
export const CHANGE_ORDER_STATUS_REVIEWED_INCORRECT = 'REVIEWED_INCORRECT';
export const CHANGE_ORDER_STATUS_AWAITING_REVISION = 'AWAITING_REVISION';
export const CHANGE_ORDER_STATUS_SIGNED = 'SIGNED';
export const CHANGE_ORDER_STATUS_RETURNED = 'RETURNED';
export const CHANGE_ORDER_STATUS_FULLY_EXECUTED = 'FULLY_EXECUTED';

export const CHANGE_ORDER_STATUS_COLORS = {
  [CHANGE_ORDER_STATUS_EXPECTED]: 'notActive',
  [CHANGE_ORDER_STATUS_RECEIVED]: 'default',
  [CHANGE_ORDER_STATUS_REVIEWED_INCORRECT]: 'error',
  [CHANGE_ORDER_STATUS_AWAITING_REVISION]: 'primary',
  [CHANGE_ORDER_STATUS_REVIEWED_CORRECT]: 'success',
  [CHANGE_ORDER_STATUS_SIGNED]: 'secondary',
  [CHANGE_ORDER_STATUS_RETURNED]: 'warning',
  [CHANGE_ORDER_STATUS_FULLY_EXECUTED]: 'success',
};

export const SERVICE_AND_WARRANTY_STATUS_RECEIVED = 'received';
export const SERVICE_AND_WARRANTY_STATUS_SCHEDULED = 'scheduled';
export const SERVICE_AND_WARRANTY_STATUS_BILLABLE = 'billable';
export const SERVICE_AND_WARRANTY_STATUS_RESOLVED = 'resolved';
export const SERVICE_AND_WARRANTY_STATUS_BILLABLE_INVOICED = 'invoiced';
export const SERVICE_AND_WARRANTY_STATUS_BILLABLE_PAID = 'paid';

export const SERVICE_AND_WARRANTY_STATUSES = [
  SERVICE_AND_WARRANTY_STATUS_RECEIVED,
  SERVICE_AND_WARRANTY_STATUS_SCHEDULED,
  SERVICE_AND_WARRANTY_STATUS_BILLABLE,
  SERVICE_AND_WARRANTY_STATUS_RESOLVED,
];

export const SERVICE_AND_WARRANTY_COLORS = {
  [SERVICE_AND_WARRANTY_STATUS_RECEIVED]: 'default',
  [SERVICE_AND_WARRANTY_STATUS_SCHEDULED]: 'primary',
  [SERVICE_AND_WARRANTY_STATUS_BILLABLE]: 'secondary',
  [SERVICE_AND_WARRANTY_STATUS_RESOLVED]: 'success',
};
