import React, { useContext } from 'react';
import Auth from '../../../services/helpers/amplify';
import api, { wsClient } from '../../../services/api';
import AppEventEmitter from '../../../services/AppEventEmitter';
import { canEdit, canView } from '../../../utils/userUtils';

export const authContextInitialValue = {
  userToken: '',
  user: {},
  isAuthenticated: false,
  pending: true,
  pendingUser: true,
  setContext: () => {},
  signIn: () => {},
  signOut: () => {},
};
const AuthContext = React.createContext(authContextInitialValue);

export function useAuth() {
  return useContext(AuthContext);
}

function apiLogin(accessToken) {
  api.setToken(accessToken.jwtToken);
  wsClient.connect({ token: accessToken.jwtToken });
}

function apiLogout() {
  wsClient.disconnect();
  api.setToken(null);
}

export class AuthContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...authContextInitialValue,
      setContext: (data) => {
        this.setState({ ...this.state, ...data });
      },
      signIn: this.signIn,
      signOut: this.signOut,
      canEdit: this.canEdit,
      canView: this.canView,
      setAzureAuthorized: this.setAzureAuthorized,
    };
  }

  componentDidMount() {
    AppEventEmitter.instance.on('unauthorized', this.setUnauthorizedListener);
    Auth.currentAuthenticatedUser()
      .then((res) => {
        const { accessToken } = res.signInUserSession;
        apiLogin(accessToken);
        this.setState({ isAuthenticated: true, pending: false, userToken: accessToken.jwtToken });
      })
      .catch(() => {
        this.setState({ isAuthenticated: false, pending: false });
      });
  }

  componentWillUnmount() {
    AppEventEmitter.instance.off('unauthorized', this.setUnauthorizedListener);
  }

  setUnauthorizedListener = () => {
    this.setState({ isAuthenticated: false });
    apiLogout();
  };

  signIn = async (login, password) => {
    return Auth.signIn(login.toLowerCase(), password).then((res) => {
      const {
        signInUserSession: { accessToken },
      } = res;
      apiLogin(accessToken);
      this.setState({ isAuthenticated: true });
    });
  };

  canEdit = (key) => {
    const { permissions } = this.state;
    return canEdit(permissions, key);
  };

  canView = (key) => {
    const { permissions } = this.state;
    return canView(permissions, key);
  };

  signOut = () => {
    Auth.signOut()
      .then(() => {
        apiLogout();
        this.setState({ isAuthenticated: false, userToken: '', user: {} });
      })
      .catch(console.error);
  };

  setAzureAuthorized = () => {
    this.setState({ user: { ...this.state.user, azure: { isAuthenticated: true } } });
  };

  render() {
    return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
  }
}
