import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 999999999,
  },
  absolute: {
    position: 'absolute',
  },
}));

export default function Loader({ open = false, fixed = false }) {
  const styles = useStyles();

  return (
    <Backdrop className={clsx(styles.root, !fixed && styles.absolute)} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
