export const QUERY_ADD = { m: 'add' };
export const QUERY_EDIT = { m: 'edit' };

export const QUERY_ADD_BID = { m: 'bid' };
export const QUERY_EDIT_BID = { m: 'bid-edit' };
export const QUERY_SEND_BID = { m: 'bid-send' };

export const QUERY_ADD_FILE = { m: 'file' };
export const QUERY_ADD_SUB = { m: 'sub' };

export const QUERY_ADD_ALT = { m: 'alt' };
export const QUERY_EDIT_ALT = { m: 'alt-edit' };

export const QUERY_ADD_COP = { m: 'cop' };
export const QUERY_EDIT_COP = { m: 'cop-edit' };

export const QUERY_ADD_CO = { m: 'co' };
export const QUERY_EDIT_CO = { m: 'co-edit' };

export const QUERY_FINANCIALS = { m: 'fin' };
export const QUERY_MATERIALS = { m: 'materials' };
export const QUERY_FIXTURES = { m: 'fixtures' };
export const QUERY_GLOBAL_SETTINGS = { g: 'y' };

export const openDetailsQuery = (id, key = 'id') => ({ [key]: id });
export const openDynamicQuery = (m, id) => ({ m, id });
