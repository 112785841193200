import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconClose = (props) => {
  const { filled = false, width = 18, height = 18, rounded = true } = props;

  return filled ? (
    <SvgIcon viewBox="0 0 20 20" width={width} height={height}>
      <path d="M10,0 C4.47,0 0,4.47 0,10 C0,15.53 4.47,20 10,20 C15.53,20 20,15.53 20,10 C20,4.47 15.53,0 10,0 Z M15,13.59 L13.59,15 L10,11.41 L6.41,15 L5,13.59 L8.59,10 L5,6.41 L6.41,5 L10,8.59 L13.59,5 L15,6.41 L11.41,10 L15,13.59 Z" />
    </SvgIcon>
  ) : (
    <SvgIcon viewBox="0 0 24 24" width={width} height={height}>
      {rounded && (
        <path
          fillOpacity=".204"
          d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2z"
          transform="translate(-882 -44) translate(520 30) translate(362 14)"
        />
      )}
      <path
        d="M9.613 8.21l.094.083L12 10.585l2.293-2.292c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094L13.415 12l2.292 2.293c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083L12 13.415l-2.293 2.292c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L10.585 12 8.293 9.707c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083z"
        transform="translate(-882 -44) translate(520 30) translate(362 14)"
      />
    </SvgIcon>
  );
};

IconClose.propTypes = simpleIconPropsTypes;
