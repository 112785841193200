import { PERM_DB_TEMPLATES } from '../perms';

export const ROUTE_REPORTS = {
  key: 'reports',
  path: '/reports',
  i18nKey: 'reports',
  icon: 'IconReport',
  permKey: PERM_DB_TEMPLATES,
};

export const ROUTE_BUILDING_MATERIALS_REPORTS = {
  key: 'building-materials',
  path: `${ROUTE_REPORTS.path}/building-materials`,
  i18nKey: 'building-materials',
  icon: 'IconBuildingMaterials',
  component: 'MaterialsReports',
  // totalApi: 'material.getTotal',
};

export const ROUTE_FIXTURES_REPORTS = {
  key: 'fixtures',
  path: `${ROUTE_REPORTS.path}/fixtures-and-specialties`,
  i18nKey: 'fixtures-and-specialties',
  icon: 'IconFixtures',
  component: 'FixturesReports',
  // totalApi: 'fixture.getTotal',
};

export const ROUTE_PIECEWORKS_REPORTS = {
  key: 'pieceworks',
  path: `${ROUTE_REPORTS.path}/pieceworks`,
  i18nKey: 'pieceworks',
  icon: 'IconLaborCost',
  component: 'PieceworksReports',
};

export const REPORTS_MENU = [ROUTE_BUILDING_MATERIALS_REPORTS, ROUTE_FIXTURES_REPORTS, ROUTE_PIECEWORKS_REPORTS];
